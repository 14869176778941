// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kM3QRZ2BM", "yEB65ZzCr", "GLBDbMsas"];

const serializationHash = "framer-9M2xg"

const variantClassNames = {GLBDbMsas: "framer-v-1o1ge0q", kM3QRZ2BM: "framer-v-1o3q0ma", yEB65ZzCr: "framer-v-1st86xo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0, ease: [0.44, 0, 0.56, 1], type: "tween"}, GLBDbMsas: {delay: 0, duration: 5, ease: [0.44, 0, 0.56, 1], type: "tween"}, yEB65ZzCr: {delay: 0, duration: 5, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Position 1": "kM3QRZ2BM", "Position 2": "yEB65ZzCr", "Position 3": "GLBDbMsas"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, DrncMqKkK: image ?? props.DrncMqKkK ?? {src: "https://framerusercontent.com/images/6Rn4em7dlQ7BkC6P9qMnG1BIvP4.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kM3QRZ2BM"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DrncMqKkK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kM3QRZ2BM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear4318k = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 1)
})

const onAppear10usw7z = activeVariantCallback(async (...args) => {
await delay(() => setVariant(CycleVariantState), 5000)
})

useOnVariantChange(baseVariant, {default: onAppear4318k, GLBDbMsas: onAppear10usw7z, yEB65ZzCr: onAppear10usw7z})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1o3q0ma", className, classNames)} data-framer-name={"Position 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"kM3QRZ2BM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({GLBDbMsas: {"data-framer-name": "Position 3"}, yEB65ZzCr: {"data-framer-name": "Position 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "min(130px, 100vw)", ...toResponsiveImage(DrncMqKkK)}} className={"framer-2f40ou"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"PfIB0ztgv"}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-9M2xg[data-border=\"true\"]::after, .framer-9M2xg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9M2xg.framer-pzy6w, .framer-9M2xg .framer-pzy6w { display: block; }", ".framer-9M2xg.framer-1o3q0ma { height: 130px; overflow: visible; position: relative; width: 130px; }", ".framer-9M2xg .framer-2f40ou { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 130px); left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-9M2xg.framer-v-1st86xo.framer-1o3q0ma, .framer-9M2xg.framer-v-1o1ge0q.framer-1o3q0ma { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 130px); }", ".framer-9M2xg.framer-v-1st86xo .framer-2f40ou { top: -50px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 130
 * @framerIntrinsicWidth 130
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"yEB65ZzCr":{"layout":["fixed","fixed"]},"GLBDbMsas":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DrncMqKkK":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerJHtcqr9xm: React.ComponentType<Props> = withCSS(Component, css, "framer-9M2xg") as typeof Component;
export default FramerJHtcqr9xm;

FramerJHtcqr9xm.displayName = "Hero Section/Hero Floating Right";

FramerJHtcqr9xm.defaultProps = {height: 130, width: 130};

addPropertyControls(FramerJHtcqr9xm, {variant: {options: ["kM3QRZ2BM", "yEB65ZzCr", "GLBDbMsas"], optionTitles: ["Position 1", "Position 2", "Position 3"], title: "Variant", type: ControlType.Enum}, DrncMqKkK: {__defaultAssetReference: "data:framer/asset-reference,6Rn4em7dlQ7BkC6P9qMnG1BIvP4.svg?originalFilename=hero+right.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerJHtcqr9xm, [])